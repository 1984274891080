import dayjs, { unix } from 'dayjs'
import utc from 'dayjs/plugin/utc'
// adds utc formatting functionality
dayjs.extend(utc)

export const formatDatetime = (datetime: string) =>
  dayjs.utc(datetime).local().format('YYYY-MM-DD HH:mm')

// Converts Ripple 64 bit time digit into utc time
// https://xrpl.org/basic-data-types.html#specifying-time
const EPOCH_OFFSET = 946684800
export const convertRippleDate = (date: number) =>
  unix(date + EPOCH_OFFSET)
    .utc()
    .format()
