import { useXRPLContext } from '../providers'
import { useQuery } from 'react-query'
import { Payment } from 'xrpl'
import { ResponseOnlyTxInfo } from 'xrpl/dist/npm/models/common'
import {
  isXRPLErrorCode,
  isXRPLErrorResponse,
  KnownXrplErrorCodes,
  matchesXRPLFilters,
} from './xrpl.helpers'

export enum XrplQueryKeys {
  AccountTransactions = 'account_tx',
  Subscribe = 'subscribe',
  Unsubscribe = 'unsubscribe',
}

export type PaymentTxResponse = Payment & ResponseOnlyTxInfo

export type PaymentTxFilterProps = {
  dateRange?: string
  endDate?: string
  paymentDirection?: string
  startDate?: string
}

export const useAccountTransactions = (
  address: string,
  filters?: PaymentTxFilterProps,
) => {
  const { client } = useXRPLContext()

  return useQuery([XrplQueryKeys.AccountTransactions, address, filters], () =>
    client
      .request({
        command: XrplQueryKeys.AccountTransactions,
        account: address,
      })
      .then((response) => {
        return response.result.transactions
          .filter(({ tx }) => {
            return matchesXRPLFilters(filters ?? {}, tx, address)
          })
          .map((transaction) => {
            return transaction.tx as PaymentTxResponse
          })
      })
      .catch((err) => {
        if (isXRPLErrorResponse(err)) {
          if (isXRPLErrorCode(err.data, KnownXrplErrorCodes.AccountMalformed)) {
            return []
          }
        }

        throw err
      }),
  )
}
