import { TRANSACTIONS } from '@/constants'
import { addressValidator, injectPathParams } from '@/helpers'
import {
  Flex,
  FormRow,
  PrimaryButton,
  rippleTheme,
  Text,
  TextField,
  useForm,
} from '@ripple/design-system'
import { useNavigate } from 'react-router-dom'

const StyledForm = rippleTheme.styled('form', {
  w: '100%',
  maxW: 448,
})

export const TxnLookupPage = () => {
  const navigate = useNavigate()
  const { formProps, getFieldProps, isValid } = useForm({
    fields: {
      address: {
        type: 'text',
        validator: addressValidator,
      },
    },
    onSubmit: ({ address }) => {
      navigate(injectPathParams(TRANSACTIONS, { address }))
    },
  })

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="center"
        direction="column"
        flex={1}
      >
        <Text weight="bold" css={{ mb: 4 }}>
          Transaction Explorer
        </Text>
        <StyledForm {...formProps}>
          <FormRow>
            <TextField {...getFieldProps('address')} label="Account Address" />
          </FormRow>
          <PrimaryButton css={{ w: '100%' }} disabled={!isValid} type="submit">
            Lookup Transactions
          </PrimaryButton>
        </StyledForm>
      </Flex>
    </>
  )
}
