import { PaymentTxFilterProps, PaymentTxResponse, XrplQueryKeys } from '@/api'
import { ROOT } from '@/constants'
import { formatDatetime, exportTransactions } from '@/helpers'
import {
  ArrowLeftIcon,
  DownloadIcon,
  Flex,
  PrimaryButton,
  RefreshIcon,
  SecondaryButton,
  Text,
  TextButton,
} from '@ripple/design-system'
import { useQueryClient } from 'react-query'
import { Link, useParams } from 'react-router-dom'

type AccountHeaderProps = {
  transactions: PaymentTxResponse[]
  filters: PaymentTxFilterProps
}

export const AccountHeader = ({
  transactions,
  filters,
}: AccountHeaderProps) => {
  const { address = '' } = useParams()
  const queryClient = useQueryClient()
  const lastUpdate = formatDatetime(new Date().toUTCString())

  return (
    <>
      <TextButton
        as={Link}
        to={ROOT}
        css={{
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          fontSize: '$300',
          fontWeight: '$bold',
          mt: 6,
          mb: 1,
        }}
      >
        <ArrowLeftIcon css={{ ml: -1 }} />
        Back to Search
      </TextButton>
      <Flex css={{ width: '100%' }} justifyContent="space-between" gap={2}>
        <Text size="lg" weight="bold">
          {address}
        </Text>
        <Flex gap={2}>
          <SecondaryButton
            onClick={() =>
              queryClient.invalidateQueries([
                XrplQueryKeys.AccountTransactions,
                address,
                filters,
              ])
            }
            icon={RefreshIcon}
            size="med"
          >
            Refresh
          </SecondaryButton>
          <PrimaryButton
            size="med"
            icon={DownloadIcon}
            onClick={() => exportTransactions(transactions, address)}
          >
            Export
          </PrimaryButton>
        </Flex>
      </Flex>
      <Text size="sm" css={{ mb: 4 }}>
        Last update at {lastUpdate} UTC
      </Text>
    </>
  )
}
