import { useXRPL } from '../hooks'
import { contextFactory, Loader } from '@ripple/design-system'
import { FC } from 'react'

const [XPRLContext, useXRPLContext] = contextFactory<
  ReturnType<typeof useXRPL>
>({
  hook: 'useXRPLContext',
  provider: 'XRPLProvider',
})

export const XRPLProvider: FC = ({ children }) => {
  const xrpl = useXRPL()

  return (
    <XPRLContext.Provider value={xrpl}>
      {xrpl.isConnected === undefined ? (
        <Loader
          css={{
            height: '100vh',
            justifyContent: 'center',
          }}
        />
      ) : (
        children
      )}
    </XPRLContext.Provider>
  )
}

export { XPRLContext, useXRPLContext }
