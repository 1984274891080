import {
  Flex,
  RippleLogo,
  rippleTheme,
  useThemeSetup,
} from '@ripple/design-system'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { NetworkBanner } from '@/components'
import { QueryClientProvider } from 'react-query'
import { queryClient, XRPLProvider } from '@/providers'
import { ROOT, TRANSACTIONS } from '@/constants'
import { AccountTransactionsPage, TxnLookupPage } from '@/pages'

const StyledMain = rippleTheme.styled('main', {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflowY: 'auto',
  p: 8,
})

const StyledPage = rippleTheme.styled(Flex, {
  width: '100vw',
  height: '100vh',
  flexWrap: 'wrap',
  overflow: 'hidden',
})

// global css for our render container to get the correct layout
const localGlobalStyles = rippleTheme.globalCss({
  body: { backgroundColor: '$background200' },
  '#root': {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
})

export const App = () => {
  useThemeSetup()
  localGlobalStyles()

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <StyledPage>
          <NetworkBanner />
          <StyledMain>
            <RippleLogo width={100} colorMode="auto" />
            <XRPLProvider>
              <Routes>
                <Route path={ROOT} element={<TxnLookupPage />} />
                <Route
                  path={TRANSACTIONS}
                  element={<AccountTransactionsPage />}
                />
              </Routes>
            </XRPLProvider>
          </StyledMain>
        </StyledPage>
      </BrowserRouter>
    </QueryClientProvider>
  )
}
