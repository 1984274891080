import { useEffect, useState } from 'react'
import { Client } from 'xrpl'
import { useXRPLSubscription } from './use-xrpl-subscription'

const MAINNET_WSS = 'wss://xrplcluster.com'

/**
 * A hook to interact with [XRPL.js](https://js.xrpl.org)
 *
 * @param options An object with the following properties:
 * - wssUrl: xrpl websocket url to connect to
 * - onConnected: function to call when client connects
 * - onDisconnected: function to call when client disconnects
 * @returns An object with the following properties:
 * - client: [xrpl.Client](https://js.xrpl.org/classes/Client.html)
 * - isConnected: undefined = connecting true = connected false = disconnected
 *
 * @todo refactor useXRPL to useSyncExternalStore when we go to react@18
 * https://beta.reactjs.org/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
 */
export const useXRPL = (
  options: {
    wssUrl?: string
    onConnected?: () => void
    onDisconnected?: () => void
  } = {},
) => {
  const opts = {
    wssUrl: MAINNET_WSS,
    onConnected: () => undefined,
    onDisconnected: () => undefined,
    ...options,
  }

  const [client] = useState<Client>(() => new Client(opts.wssUrl))
  const [isConnected, setIsConnected] = useState<boolean>()

  useXRPLSubscription(client, {
    connected: () => {
      console.debug('[xrpl] connected.', client.url)
      setIsConnected(true)
      opts.onConnected()
    },
    disconnected: () => {
      console.debug('[xrpl] disconnected.', client.url)
      setIsConnected(false)
      opts.onDisconnected()
    },
    error: () => {
      console.debug('[xrpl] error.')
    },
  })

  useEffect(() => {
    console.debug('[xrpl] connecting...', client.url)

    client.connect()

    return () => {
      console.debug('[xrpl] disconnecting...', client.url)
      client.disconnect()
    }
  }, [client])

  return {
    client,
    isConnected,
  }
}
