import { isObject, isUndefined } from '@ripple/ui-helpers'
import dayjs, { unix } from 'dayjs'
import { ErrorResponse, Transaction, XrplError } from 'xrpl'
import { ResponseOnlyTxInfo } from 'xrpl/dist/npm/models/common'
import { PaymentTxFilterProps } from './xrpl.queries'
import utc from 'dayjs/plugin/utc'
// adds utc formatting functionality
dayjs.extend(utc)

// Converts Ripple 64 bit time digit into utc time
// https://xrpl.org/basic-data-types.html#specifying-time
const EPOCH_OFFSET = 946684800
export const convertRippleDate = (date: number) =>
  unix(date + EPOCH_OFFSET)
    .utc()
    .format()

/**
 * Subset of XRPLErrorCodes that we handle from the [full list](https://github.com/XRPLF/rippled/blob/develop/src/ripple/protocol/ErrorCodes.h#L70)
 */
export enum KnownXrplErrorCodes {
  AccountNotFound = 19,
  AccountMalformed = 35,
}

/**
 * Check if XRPL.js ErrorResponse is the provided errorCode
 * @param {ErrorResponse} error
 * @param {KnownXrplErrorCodes} errorCode Error code to check against
 * @returns if ErrorResponse.error_code matches provided errorCode
 */
export const isXRPLErrorCode = (
  error: ErrorResponse,
  errorCode: KnownXrplErrorCodes,
) => !isUndefined(error.error_code) && parseInt(error.error_code) === errorCode

/**
 * Check if error is coming from XRPL.js
 * @param thing Maybe an XRPL.js error object
 */
export const isXRPLErrorResponse = (
  thing: unknown,
): thing is { data: ErrorResponse } =>
  thing instanceof XrplError &&
  isObject(thing.data) &&
  thing.data.status === 'error' &&
  thing.data.type === 'response'

/**
 * Checks if a transaction matches provided filters.
 * @param filters Filters to check
 * @param tx Transaction to check
 * @param address Address of account for transactions being checked. Used to determine payment direction.
 * @returns {boolean} Boolean indicating if it matches the filters
 */
export const matchesXRPLFilters = (
  filters: PaymentTxFilterProps,
  tx: (Transaction & ResponseOnlyTxInfo) | undefined,
  address: string,
) => {
  // only look at payment txns
  if (tx?.TransactionType !== 'Payment') return false
  // filter against direction if set
  if (filters?.paymentDirection && filters.paymentDirection !== 'All') {
    const paymentDirection = address === tx.Destination ? 'Received' : 'Sent'
    if (filters.paymentDirection !== paymentDirection) return false
  }
  // filter against startDate if set
  if (filters?.startDate && tx?.date) {
    const txDate = convertRippleDate(tx.date)
    if (dayjs(txDate).isBefore(filters.startDate, 'day')) return false
  }
  // filter against endDate if set
  if (filters?.endDate && tx?.date) {
    const txDate = convertRippleDate(tx.date)
    if (dayjs(txDate).isAfter(filters.endDate, 'day')) {
      return false
    }
  }
  // matches all filters
  return true
}
