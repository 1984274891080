import { getNextDay, getPreviousDay, toISO } from '@/helpers'
import {
  DateField,
  DropdownField,
  Flex,
  PrimaryButton,
  TertiaryButton,
  useForm,
} from '@ripple/design-system'
import dayjs from 'dayjs'
import type { PaymentTxFilterProps } from '../api'

type AccountFiltersProps = PaymentTxFilterProps & {
  onCancel: () => void
  onSave: (filters: PaymentTxFilterProps) => void
}

const CUSTOM_RANGE = 'custom'

const fieldCSS = { minW: 'fit-content', w: 'fit-content' }

export const AccountEditFilters = ({
  dateRange,
  endDate,
  onCancel,
  onSave,
  paymentDirection,
  startDate,
}: AccountFiltersProps) => {
  const end = toISO()

  const dateOptions: { [key: string]: { start: string; end: string } } = {
    all: { start: '', end: '' },
    [CUSTOM_RANGE]: { start: '', end },
    day: { start: toISO(dayjs().subtract(1, 'day')), end },
    week: { start: toISO(dayjs().subtract(1, 'week')), end },
    month: { start: toISO(dayjs().subtract(30, 'day')), end },
  }

  const { formProps, getFieldProps, isValid } = useForm({
    fields: {
      dateRange: {
        type: 'dropdown',
        value: dateRange,
      },
      paymentDirection: {
        type: 'dropdown',
        value: paymentDirection,
      },
      startDate: {
        type: 'date',
        required: false,
        value: startDate,
      },
      endDate: {
        type: 'date',
        required: false,
        maxDate: end,
        value: endDate,
      },
    },
    onFieldChange: {
      dateRange: ({ value }, { updateFields }) => {
        updateFields({
          startDate: {
            value: dateOptions[value].start,
            // set min to one day less than end (so can't be same day)
            maxDate:
              value === CUSTOM_RANGE
                ? getPreviousDay(dateOptions[value].end)
                : undefined,
          },
          endDate: { value: dateOptions[value].end },
        })
      },
      startDate: ({ error, value }, { updateFields }) => {
        if (!error) {
          const nextDay = dayjs(value).add(1, 'day')
          // bail if trying to set minDate to today or later
          if (nextDay.isAfter(end) || nextDay.isSame(end, 'day')) return
          // set min to one day more than start (so can't be same day)
          updateFields({
            endDate: { minDate: getNextDay(value) },
          })
        }
      },
      endDate: ({ error, value }, { updateFields }) => {
        if (!error) {
          // set max to one day less than end (so can't be same day)
          updateFields({
            startDate: { maxDate: getPreviousDay(value) },
          })
        }
      },
    },
    onSubmit: ({ dateRange, startDate, endDate, ...rest }) => {
      // for custom date ranges, might be missing both start and end
      if (dateRange === CUSTOM_RANGE) {
        if (
          // neither
          (!startDate && !endDate) ||
          // or end but no start and end is today
          (endDate && !startDate && dayjs(endDate).isSame(end, 'day'))
        ) {
          // same as 'All Time'
          return onSave({
            ...rest,
            dateRange: 'all',
            endDate: '',
            startDate: '',
          })
        }
      }

      return onSave({
        ...rest,
        dateRange,
        endDate: endDate || '',
        startDate: startDate || '',
      })
    },
  })

  const dateRangeFieldProps = getFieldProps('dateRange')
  const showDateFields = dateRangeFieldProps.value === CUSTOM_RANGE

  return (
    <Flex as="form" {...formProps} alignItems="center" gap={2} css={{ mb: 2 }}>
      <DropdownField
        {...dateRangeFieldProps}
        fieldSize="sm"
        label="Date range"
        startAdornment={'Date: '}
        css={fieldCSS}
        disableEmptyOption={true}
      >
        <DropdownField.Option value="all">All Time</DropdownField.Option>
        <DropdownField.Option value="day">Last 24 hours</DropdownField.Option>
        <DropdownField.Option value="week">Last 7 days</DropdownField.Option>
        <DropdownField.Option value="month">Last 30 days</DropdownField.Option>
        <DropdownField.Option value={CUSTOM_RANGE}>Range</DropdownField.Option>
      </DropdownField>
      {showDateFields && (
        <>
          <DateField
            {...getFieldProps('startDate')}
            startAdornment="Start Date:"
            fieldSize="sm"
            label="Start date"
            css={fieldCSS}
          />
          <DateField
            {...getFieldProps('endDate')}
            startAdornment="End Date:"
            fieldSize="sm"
            label="End date"
            css={fieldCSS}
          />
        </>
      )}
      <DropdownField
        {...getFieldProps('paymentDirection')}
        fieldSize="sm"
        startAdornment={'Payment Direction: '}
        label="Payment direction"
        css={fieldCSS}
        disableEmptyOption={true}
      >
        <DropdownField.Option value="All">All</DropdownField.Option>
        <DropdownField.Option value="Received">Received</DropdownField.Option>
        <DropdownField.Option value="Sent">Sent</DropdownField.Option>
      </DropdownField>
      <TertiaryButton size="xs" type="button" onClick={onCancel}>
        Cancel
      </TertiaryButton>
      <PrimaryButton type="submit" size="xs" disabled={!isValid}>
        Apply Filters
      </PrimaryButton>
    </Flex>
  )
}
