import { AlertBanner } from '@ripple/design-system'
import { Network } from 'react-fns'

export const NetworkBanner = () => (
  <Network
    render={({ online }) =>
      !online && (
        <AlertBanner type="error" css={{ flexBasis: '100%' }}>
          You appear to be offline. Please check your connection.
        </AlertBanner>
      )
    }
  />
)
