import { PaymentTxFilterProps, useAccountTransactions } from '@/api'
import {
  PaginationControls,
  usePaginationControls,
} from '@ripple/design-system'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  AccountEditFilters,
  AccountFilters,
  AccountHeader,
  AccountTransactions,
  FullScreenLoader,
} from '@/components'

export const AccountTransactionsPage = () => {
  const { address = '' } = useParams()
  const [filters, setFilters] = useState<PaymentTxFilterProps>({
    paymentDirection: 'All',
    dateRange: 'all',
  })
  const { data: transactions } = useAccountTransactions(address, filters)
  const [isEditingFilters, setIsEditingFilters] = useState(false)
  const { page, pageSize, controlProps } = usePaginationControls()
  const transactionsSubset = transactions
    ? transactions.slice(page * pageSize, pageSize * (page + 1))
    : []

  return (
    <>
      {!transactions ? (
        <FullScreenLoader />
      ) : (
        <>
          <AccountHeader filters={filters} transactions={transactions} />
          {isEditingFilters ? (
            <AccountEditFilters
              {...filters}
              onCancel={() => {
                setIsEditingFilters(false)
              }}
              onSave={(filters) => {
                setFilters(filters)
                setIsEditingFilters(false)
              }}
            />
          ) : (
            <AccountFilters
              onEditFilters={() => {
                setIsEditingFilters(true)
              }}
              filters={filters}
            />
          )}
          <AccountTransactions
            address={address}
            transactions={transactionsSubset}
          />
          <PaginationControls
            {...controlProps}
            css={{ mt: 3 }}
            totalItems={transactions.length}
          />
        </>
      )}
    </>
  )
}
