import { Loader } from '@ripple/design-system'

export const FullScreenLoader = () => {
  return (
    <Loader
      size="med"
      css={{
        h: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    />
  )
}
