import { useEffect } from 'react'
import {
  Client,
  ConsensusStream,
  LedgerStream,
  PathFindStream,
  PeerStatusStream,
  TransactionStream,
  ValidationStream,
} from 'xrpl'

type XRPLEventHandlers = {
  connected?: () => void
  disconnected?: (code: number) => void
  ledgerClosed?: (ledger: LedgerStream) => void
  validationReceived?: (validation: ValidationStream) => void
  transaction?: (tx: TransactionStream) => void
  peerStatusChange?: (status: PeerStatusStream) => void
  consensusPhase?: (phase: ConsensusStream) => void
  pathFind?: (path: PathFindStream) => void
  error?: (...err: any[]) => void
}

const setupHandlers = <Handlers extends XRPLEventHandlers>(
  client: Client,
  handlers: Handlers,
  action: 'on' | 'off',
) => {
  for (const [key, handler] of Object.entries(handlers)) {
    // @ts-expect-error TS can't precisely map handler keys to values so ignore it
    client[action](key, handler)
  }
}

export const useXRPLSubscription = (
  client: Client,
  handlers: XRPLEventHandlers,
) => {
  useEffect(() => {
    setupHandlers(client, handlers, 'on')

    return () => {
      setupHandlers(client, handlers, 'off')
    }
  }, [client, handlers])
}
