import { isString } from '@ripple/ui-helpers'
import { dropsToXrp, Payment } from 'xrpl'
import { convertRippleDate, formatDatetime } from './datetime.helpers'
import { convertHexCurrencyCodeToString } from './currency-code.helpers'

export const getTxnDestinationAmount = (amount: Payment['Amount']) =>
  isString(amount) ? dropsToXrp(+amount) : amount.value

export const getTxnCurrency = (amount: Payment['Amount']) =>
  isString(amount) ? 'XRP' : convertHexCurrencyCodeToString(amount.currency)

export const getFormattedTxnDate = (date?: number) =>
  date ? formatDatetime(convertRippleDate(date)) : 'Date unavailable'
