import { PaymentTxResponse } from '@/api'
import {
  getFormattedTxnDate,
  getTxnCurrency,
  getTxnDestinationAmount,
} from '@/helpers'
import { Flex, Table, Text } from '@ripple/design-system'
import { formatNumber } from '@ripple/ui-helpers'

type AccountTransactionsProps = {
  transactions: PaymentTxResponse[]
  address: string
}

export const AccountTransactions = ({
  transactions,
  address,
}: AccountTransactionsProps) => {
  return (
    <>
      <Table>
        <Table.Header>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>Payment Direction</Table.HeaderCell>
          <Table.HeaderCell>Date & Time</Table.HeaderCell>
          <Table.HeaderCell>From</Table.HeaderCell>
          <Table.HeaderCell>To</Table.HeaderCell>
          <Table.HeaderCell>Amount</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {transactions.map(
            (
              {
                date,
                Account: source,
                Amount: amount,
                Destination: destination,
              },
              index,
            ) => {
              // Converts XRP currency amounts which are of type string to readable values
              const destinationAmount = getTxnDestinationAmount(amount)
              const decimals = destinationAmount.split('.')[1]?.length ?? 0
              const currency = getTxnCurrency(amount)
              const paymentDirection =
                address === destination ? 'Received' : 'Sent'

              return (
                <Table.Row key={`${date}-${index}`}>
                  <Table.Cell>Payment</Table.Cell>
                  <Table.Cell>{paymentDirection}</Table.Cell>
                  <Table.Cell>{getFormattedTxnDate(date)}</Table.Cell>
                  <Table.Cell>{source}</Table.Cell>
                  <Table.Cell>{destination}</Table.Cell>
                  <Table.Cell>
                    <Flex gap={0.5}>
                      <Text
                        css={{ fontFeatureSettings: '"tnum" on, "lnum" on' }}
                      >
                        {formatNumber(+destinationAmount, { decimals })}
                      </Text>
                      <Text weight="bold">{currency}</Text>
                    </Flex>
                  </Table.Cell>
                </Table.Row>
              )
            },
          )}
        </Table.Body>
      </Table>
      {transactions.length === 0 && (
        <Flex css={{ mt: 5 }} justifyContent="center">
          No transactions to display
        </Flex>
      )}
    </>
  )
}
