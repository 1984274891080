import type { PaymentTxResponse } from '@/api'
import { isString } from '@ripple/ui-helpers'
import dayjs from 'dayjs'
import { downloadAsCsv } from './file-downloader.helpers'
import {
  getFormattedTxnDate,
  getTxnCurrency,
  getTxnDestinationAmount,
} from './xrpl-txn-helpers'

const ISO_FORMAT = 'YYYY-MM-DD'

export const toISO = (d: string | dayjs.Dayjs = dayjs()) => {
  if (isString(d)) return dayjs(d).format(ISO_FORMAT)
  return d.format(ISO_FORMAT)
}

export const getNextDay = (d: string | dayjs.Dayjs) =>
  toISO((isString(d) ? dayjs(d) : d).add(1, 'day'))

export const getPreviousDay = (d: string) => toISO(dayjs(d).subtract(1, 'day'))

export const exportTransactions = (
  transactions: PaymentTxResponse[],
  address: string,
) => {
  const csv = transactions.map(
    ({ Account: source, date, Destination: destination, Amount: amount }) => {
      const paymentDirection = address === destination ? 'Received' : 'Sent'
      const destinationAmount = getTxnDestinationAmount(amount)
      const currency = getTxnCurrency(amount)

      return [
        'Payment',
        paymentDirection,
        getFormattedTxnDate(date),
        source,
        destination,
        `${destinationAmount} ${currency}`,
      ].join(',')
    },
  )

  downloadAsCsv(
    csv,
    'Type,Payment Direction,Date & Time,From,To,Amount',
    `transactions_${address}_${dayjs().format('YYYY-MM-DD')}.csv`,
  )
}
