import type { PaymentTxFilterProps } from '../api'
import { Chip, Flex, TertiaryButton } from '@ripple/design-system'

type AccountFiltersProps = {
  filters: PaymentTxFilterProps
  onEditFilters: () => void
}

const dateRangeDisplayText: { [key: string]: string } = {
  all: 'All Time',
  day: 'Last 24 hours',
  week: 'Last 7 days',
  month: 'Last 30 days',
  custom: 'Range',
}

const getDateFilterText = ({
  dateRange,
  startDate,
  endDate,
}: PaymentTxFilterProps) => {
  if (dateRange === 'custom') {
    if (startDate) {
      // start only
      if (!endDate) return `Date is from ${startDate}`
      // both
      return `Date range is ${startDate} to ${endDate}`
    }
    // end only
    return `Date is through ${endDate}`
  }

  return dateRange ? `Date is ${dateRangeDisplayText[dateRange]}` : ''
}

export const AccountFilters = ({
  filters,
  onEditFilters,
}: AccountFiltersProps) => (
  <Flex css={{ mb: 2 }} gap={2}>
    <Chip size="med" text={getDateFilterText(filters)} color="gray" />
    <Chip
      size="med"
      text={`Payment Direction is ${filters.paymentDirection}`}
      color="gray"
    />
    <TertiaryButton color="blue" size="sm" onClick={onEditFilters}>
      Edit Filters
    </TertiaryButton>
  </Flex>
)
